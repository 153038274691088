<template>
  <div>
    <AppMenu />

    <!-- m="4" md="4" lg="4" -->
    <div tabindex="0">
      <v-row no-gutters>
        <v-col cols="12">
          <BaseBreadcrumb :title="$t(title)">
            <template v-slot:actions>
              <div class="d-flex justify-end">
                <v-btn
                  small
                  class="mr-2"
                  color="primary"
                  :title="$t('list.filter')"
                  @click="showFilters = true"
                >
                  <v-icon left>mdi-filter</v-icon> {{ $t("list.filter") }}
                </v-btn>
              </div>
            </template>
          </BaseBreadcrumb>
          <v-card class="mt-3 mx-3">
            <div class="pl-2 pt-2">
              <div class="d-flex justify-star pl-2 mt-2 mb-2" v-show="form">
                <ng-filters-chips
                  :form="form"
                  v-show="form"
                  @change="search(form, 1)"
                  :labels="{
                    product_type: 'product_type',
                    reference: 'reference',
                    name: 'name',
                    category: 'category',
                  }"
                >
                </ng-filters-chips>
              </div>
              <ValidationObserver
                v-slot="{}"
                v-if="showFilters"
                ref="form"
                class="panel-filter"
                v-click-outside="onClickOutside"
              >
                <div class="mt-2" style="height: 100%">
                  <div class="d-flex justify-end px-4 mt-4">
                    <v-btn
                      small
                      class="btn-margin0"
                      color="grey"
                      outlined
                      @click="showFilters = false"
                    >
                      <v-icon left>mdi-close</v-icon>{{ $t("Cerrar") }}
                    </v-btn>
                    <v-btn
                      small
                      class="btn-margin0 ml-1"
                      color="primary"
                      @click="searchAndClose(form, 1)"
                    >
                      <v-icon left>mdi-filter</v-icon> {{ $t("Buscar") }}
                    </v-btn>
                  </div>
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col cols="12" class="ml-2 mb-2">{{
                        $t("list.filter")
                      }}</v-col>
                      <v-col cols="12"
                        ><ng-text
                          v-model="form.local_reference"
                          :label="$t('reference')"
                        ></ng-text
                      ></v-col>
                    </v-row>
                  </v-container>
                </div>
              </ValidationObserver>
            </div>
            <div v-if="$store.getters.mobile">
              <v-list class="list-mobile">
                <v-divider></v-divider>
                <div v-for="item in items" :key="item.id">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("reference") }}:
                          </span>
                          <span>{{ item.local_reference }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("date") }}:
                          </span>
                          <span>{{ item.date_order }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("information") }}:
                          </span>
                          <span>{{ item.date_order }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("total") }}:
                          </span>
                          <span>{{
                            $filters.currency(item.total_to_pay, 0)
                          }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("balance") }}:
                          </span>
                          <span>{{
                            $filters.currency(item.total_to_pay, 0)
                          }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"
                            >{{ $t("order_status") }}:
                          </span>
                          <span>{{ $t(item.order_status) }}</span>
                        </div>
                      </v-list-item-title>
                      <v-list-item-title>
                        <div
                          class="d-flex justify-space-between list-mobile-field"
                        >
                          <span v-if="title" class="title-td"></span>
                          <span>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-arrow-down</v-icon>
                                  {{ $t("actions") }}
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item class="d-flex flex-column mt-4">
                                  <v-btn
                                    block
                                    small
                                    class="mb-1"
                                    :title="$t('print_order')"
                                    elevation="1"
                                    @click="executePrintOrder(item)"
                                  >
                                    <v-icon>mdi-printer</v-icon>
                                    {{ $t("print_order") }}
                                  </v-btn>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </span>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="item.id"></v-divider>
                </div>
              </v-list>
            </div>
            <div v-else class="px-2">
              <v-data-table
                mobile-breakpoint="300"
                :options.sync="options"
                :disable-filtering="true"
                v-on:update:page="changePage"
                v-on:update:sort-desc="changeOrder"
                v-on:update:items-per-page="changeItemsPerPage"
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                class="listing-app"
                item-key="id"
                :show-expand="false"
                :expanded.sync="expanded"
                :footer-props="{
                  'items-per-page-options': options.itemsPerPageOptions,
                  'items-per-page-text': $t('list.items_per_page'),
                }"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:header.id>
                  <span class="field-uuid">{{ $t("id") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.consecutive_order_text>
                  <span class="field-reference">{{
                    $t("consecutive_order_text")
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.local_reference>
                  <span class="field-string">{{ $t("reference") }}</span>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:header.date_order>
                  <span class="field-reference">{{ $t("date_order") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.total_to_pay>
                  <span class="field-string">{{ $t("total") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header.order_status>
                  <span class="field-string">{{ $t("order_status") }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:header._actions>
                  <span class="field-string">{{ $t("actions") }}</span>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.id="{ item }">
                  <span class="field-uuid">{{ item.id }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.consecutive_order_text="{ item }">
                  <span class="field-string">{{
                    item.consecutive_order_text
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.local_reference="{ item }">
                  <span class="field-string">{{ item.local_reference }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.date_order="{ item }">
                  <span class="field-date">{{
                    $filters.datetime(item.date_order)
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.total_to_pay="{ item }">
                  <span class="field-string">{{
                    $filters.currency(item.total_to_pay, 0)
                  }}</span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.order_status="{ item }">
                  <span class="field-string">
                    <v-chip
                      small
                      dark
                      :color="setStatusColor(item.order_status)"
                    >
                      {{ $t(item.order_status) }}
                    </v-chip>
                  </span>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item._actions="{ item }">
                  <v-btn
                    small
                    tile
                    plain
                    class="mr-1"
                    :title="$t('print_order')"
                    @click="executePrintOrder(item)"
                  >
                    <v-icon>mdi-printer</v-icon>
                    {{ $t("order") }}
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <div
              v-if="$store.getters.mobile"
              class="d-flex justify-center pt-1 pb-2"
            >
              <v-btn
                small
                color="primary"
                @click="loadMore"
                :loading="loadingMore"
                >{{ $t("view_more_with_points") }}</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import AppMenu from "../menu/AppMenu.vue";

import BaseListPage from "@/components/mixins/BaseListPage";
import MixinActions from "@/components/mixins/MixinListActions";
import MixinModalDynamic from "@/components/mixins/MixinModalDynamic";

export default {
  name: "ServerOrders",
  mixins: [BaseListPage, MixinActions, MixinModalDynamic],
  components: { AppMenu },
  data: () => ({
    app_version: null,
    title: "Ordenes Servidor",
    form: {},
    url: "api/v1/order",
    pathBase: "/pos/server-orders",
    options: {
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 25, 50, 100],
    },
    headers: [
      { title: "consecutive_order_text", value: "consecutive_order_text" },
      { title: "local_reference", value: "local_reference" },
      { title: "date_order", value: "date_order" },
      { title: "info", value: "info" },
      { title: "total_to_pay", value: "total_to_pay" },
      { title: "order_status", value: "order_status" },
      { title: "_actions", value: "_actions" },
    ],
    tmplShow: `<v-dialog v-model="dialog" width="500" style="background-color:white">
                <v-card class="pa-3">
                <v-row no-gutters>

                    <v-col cols="12" class=" list-mobile-field" > {{$t('product_type')}}:  {{$filters.name(item.product_type)}}</v-col>
                    <v-col cols="12" class=" list-mobile-field" > {{$t('reference')}}:  {{item.reference}}</v-col>
                    <v-col cols="12" class=" list-mobile-field" > {{$t('name')}}:  {{item.name}}</v-col>
                    <v-col cols="12" class=" list-mobile-field" > {{$t('category')}}:  {{$filters.name(item.category)}}</v-col>
                    <v-col cols="12" class=" list-mobile-field" > {{$t('image')}}:  {{item.image}}</v-col>
                    <v-col cols="12" class=" list-mobile-field" > {{$t('active')}}:  {{$filters.boolean(item.active)}}</v-col>
                </v-row>
                <v-card-actions class="d-flex justify-end">
                    <v-btn outlined color="primary" @click="close" small> {{$t('Cerrar')}} </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>`,
    //cart: null
    //priceList:
  }),

  mounted() {
    this.load();
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    launchMenuPos(evt) {
      return false;
    },
    async load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
        await this.$db.load();
        const installed = await this.$db.itsInstalled();
        if (!installed) {
          this.openInstall = true;
        } else {
          this.installedEvent();
        }
      }, 50);
    },
    async installedEvent() {
      this.$pos.priceLists = await this.$db.getPriceLists();
      this.installed = true;
      this.openInstall = false;
      this.key++;
      this.keyReloadProducts++;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    getParamsDefault(params) {
      // return {"subsidiary" : { id: 1 }};
    },
    setStatusColor(status) {
      switch (status) {
        case "create":
          return "primary";
          break;
        case "pending":
          return "warning";
          break;
        default:
          return "error";
          break;
      }
    },
    executeAnulate(item) {},
    executePrintOrder(item) {
      this.$http
        .get("api/v1/order/" + item.id, {}, true, false)
        .then((response) => {
          if (response.success) {
            if (response.item) {
              this.$pos.printOrderServer(response.item);
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async search(params, page) {
      let installation = this.$store.getters.installation;
      if (!installation) {
        setTimeout(() => {
          this.search(params, page);
        }, 1500);
        return;
      }
      const { data, paginate, order } = this.getParamsForQuery(params, page);
      this.querySelections(
        { ...data, subsidiary: { id: installation.subsidiary_id } },
        paginate,
        order
      ).then((data) => {
        this.items = data.items;
        this.total = data.total;
        this.moduleActions = data.module_actions;
        this.itemsKey++;
      });
    },
  },
};
</script>
